<!--  -->
<template>
  <div class="notice-detail-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '详情',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <div class="detail-info" v-if="detail">
        <div class="notice-title-info">
          <div class="notice-title">{{ detail.naTitle }}</div>
          <p class="name-time">
            <span class="name">{{ detail.departmentNames }}</span>
            <span class="time">{{ detail.onlineTime }}</span>
          </p>
        </div>
        <div class="split-line"></div>
        <div class="content-info">
          <iframe
            style="width: 100%; margin-top: 20px"
            id="article"
            frameborder="0"
            :style="{ height: iframeHeight + 'px' }"
          ></iframe>
        </div>
        <div class="link-txt" v-if="detail.fileUrls && detail.fileUrls.length">
          <p class="label">附件：</p>
          <span
            class="file"
            v-for="(file, f) in detail.fileUrls"
            :key="f"
            @click="jumpUrl(file)"
            >{{ file.originFileName }}</span
          >
        </div>
      </div>
      <van-empty v-else description="未获取到信息" />
    </div>
  </div>
</template>

<script>
import { noticeInfo } from "@/api/notice/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      topObj: {
        bgColor: "#fff",
        title: "详情",
        txtColor: "#000",
        isBack: true,
        isMsg: false,
      },
      detail: null,
      iframeHeight: 0,
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    if (this.$route.query) {
      let { id } = this.$route.query;
      if (id) {
        this.getNoticeInfo(id);
      }
    }
  },

  methods: {
    jumpUrl(item) {
      if (window.plus) {
        // 创建下载任务
        const picurl = item.fileUrl,
          that = this;
        //图片保存到手机后的路径
        let picname = `_downloads/${item.originFileName}`;
        var dtask = plus.downloader.createDownload(
          picurl,
          {
            filename: picname,
          },
          function (d, status) {
            // 下载完成
            if (status == 200) {
              // plus.gallery.save(
              //   d.filename, //这个很关键记得要填d 返回的名字
              //   function () {
              //     //保存到相册方法
              //     that.show = false;
              //     that.$toast("已保存到手机");
              //   },
              //   function (e) {
              //     that.show = false;
              //     that.$toast("保存失败，请重试！");
              //   }
              // );
              // that.$toast("已保存到手机相册");
              plus.runtime.openFile(d.filename, {}, function (e) {
                that.$toast("无法打开此文件");
              });
            } else {
              that.$toast("Download failed: " + status);
            }
          }
        );
        dtask.start(); //开始下载
      } else {
        window.open(item.fileUrl);
      }
    },
    //iframe 保证样式隔离
    viewArticle() {
      const b = document.getElementById("article").contentWindow.document.body;
      b.style.margin = "0";
      b.style.overflowX = "auto";
      b.style.overflowY = "hidden";
      b.style.padding = "10px";
      b.innerHTML = this.detail.naContent;
      setInterval(() => {
        this.iframeHeight = b.offsetHeight;
      }, 100);
    },
    getNoticeInfo(id) {
      noticeInfo(id).then((res) => {
        if (res) {
          let { code, data } = res;
          if (code == 200) {
            this.detail = data;
            this.$nextTick(() => {
              this.viewArticle();
            });
          }
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval();
  },
};
</script>
<style lang="scss" scoped>
.notice-detail-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  position: relative;
  .main-wrapper {
    height: 100%;
    width: 100%;
    .detail-info {
      margin-top: 0.3rem;
      width: 100%;
      min-height: 15rem;
      background: #ffffff;
      border-radius: 0.4rem;
      .notice-title-info {
        padding: 0.3rem;
        width: 100%;
        .notice-title {
          width: 100%;
          text-align: center;
          font-size: 0.34rem;
          font-weight: 500;
          color: #000000;
          line-height: 0.48rem;
        }
        .name-time {
          font-size: 0.26rem;
          margin-top: 0.24rem;
          font-weight: 400;
          color: #666666;
          line-height: 0.28rem;
          display: flex;
          justify-content: center;
          .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .time {
            min-width: 2.5rem;
            margin-left: 0.3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .split-line {
        width: 100%;
        height: 0.2rem;
        background: #fafafa;
      }
      .content-info {
        padding: 0.3rem;
        width: 100%;
        min-height: 9rem;
        // overflow: hidden;
      }
      .link-txt {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0.3rem;
        padding-bottom: 0rem;
        .label {
          width: 1rem;
          height: 0.32rem;
          font-size: 0.28rem;
          font-weight: 500;
          color: #333333;
          line-height: 0.32rem;
          margin-bottom: 0.3rem;
        }
        .file {
          width: 100%;
          height: 0.28rem;
          font-size: 0.26rem;
          font-weight: 400;
          color: #0094ee;
          line-height: 0.28rem;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}
</style>
